import * as generateToChat from './viewer-script-generator';

export const chatApi = {
  //usage: $w('#wixChat1').onMessage(callbackToBeCalledWhenANewMessageArrivedOrSent)
  onMessage: callback => {
    generateToChat.callbackEventWithTransformMessage(
      'ChatWidget.onMessageReceived',
      callback,
      'Received',
    );
    generateToChat.callbackEventWithTransformMessage(
      'ChatWidget.onMessageSent',
      callback,
      'Sent',
    );
    return;
  },

  //usage: $w('#wixChat1').onWidgetResize(callbackToBeCalledWhenWidgetCollapsedOrExpand)
  onWidgetResize: callback => {
    generateToChat.callbackEvent(
      'ChatWidget.onWidgetCollapsed',
      callback,
      'Collapsed',
    );
    generateToChat.callbackEvent(
      'ChatWidget.onWidgetExpand',
      callback,
      'Expand',
    );
    return;
  },

  //usage: $w('#wixChat1').startPrivateChat(userId)
  startPrivateChat: userId =>
    generateToChat.request('ChatWidget.startPrivateChat', {
      userId,
    }),

  //usage: $w('#wixChat1').sendMessage(message, chatroom)
  sendMessage: (message, chatroom) =>
    generateToChat.request('ChatWidget.sendMessage', { message, chatroom }),

  //usage: $w('#wixChat1').expandWidget()
  expandWidget: () => generateToChat.request('ChatWidget.expandWidget', {}),

  //usage: $w('#wixChat1').expandWidget()
  collapseWidget: () => generateToChat.request('ChatWidget.collapseWidget', {}),

  //usage: const isMembersChatEnabled = await $w('#wixChat1').isMembersChatEnabled()
  isMembersChatEnabled: () =>
    generateToChat.requestWithResult(
      'ChatWidget.getChatSettings',
      {},
      'isSocialChat',
    ),

  //usage: const isMembersChatEnabled = await $w('#wixChat1').isMembersChatEnabled()
  isBusinessChatEnabled: () =>
    generateToChat.requestWithResult(
      'ChatWidget.getChatSettings',
      {},
      'isBusinessChat',
    ),

  //usage: const isWidgetCollapsed = await $w('#wixChat1').isWidgetCollapsed()
  isWidgetCollapsed: () =>
    generateToChat.requestWithResult(
      'ChatWidget.getChatState',
      {},
      'isWidgetCollapsed',
    ),

  //usage: const isWidgetAvailable = await $w('#wixChat1').isWidgetAvailable()
  isWidgetAvailable: () =>
    generateToChat.requestWithResult(
      'ChatWidget.getChatState',
      {},
      'isWidgetAvailable',
    ),

  //usage: const isWidgetAllowInput = await $w('#wixChat1').isWidgetAllowInput()
  isWidgetAllowInput: () =>
    generateToChat.requestWithResult(
      'ChatWidget.getChatState',
      {},
      'isWidgetAllowInput',
    ),

  //usage: const currentChatRoom = await $w('#wixChat1').getCurrentChatroom()
  getCurrentChatroom: () =>
    generateToChat.requestWithResult(
      'ChatWidget.getChatState',
      {},
      'currentChatroom',
    ),

  //usage: const businessChatroom = await $w('#wixChat1').getBusinessChatroom()
  getBusinessChatroom: () =>
    generateToChat.requestWithResult(
      'ChatWidget.getChatState',
      {},
      'businessChatRoom',
    ),

  //usage: const wasWidgetExpanded = await $w('#wixChat1').wasWidgetExpanded()
  wasWidgetExpanded: () =>
    generateToChat.requestWithResult(
      'ChatWidget.getChatState',
      {},
      'wasWidgetExpanded',
    ),
};

const pageReadyImpl = $w => {};

export const createControllers = controllerConfigs =>
  controllerConfigs.map(() =>
    Promise.resolve({
      pageReady: pageReadyImpl,
      exports: chatApi,
    }),
  );

export const initAppForPage = ({ instance }, { pubSub }) =>
  generateToChat.initAppForPage({ instance }, { pubSub });
